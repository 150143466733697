.banner {
  width: 100vw;
  position: relative;
  height: 12.5rem; //200px
  text-align: center;
  background-color: $brand-primary;
  box-shadow: 0 12px 24px rgba(17,16,62,.12);

  @include media-breakpoint-down(md) {
    height: 5rem; //80px
  }

  .navbar {
    flex-wrap: wrap;
    position: relative;

    @include media-breakpoint-down(md) {
      height: 5rem; //80px
      padding: 1rem 0
    }

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }

  .brand {
    display: flex;
    width: 100vw;
    height: 11.25rem; //180px
    padding: 0;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      display: flex;
      max-width: 15rem; //240px
      height: 3.125rem; //50px
      align-items: center;
      justify-content: left;
      margin-left: 15px;
    }

    @include media-breakpoint-up(sm) {

    }

    img {
      height: 6.25rem; //160px

      @include media-breakpoint-down(md) {
        width: 13.75rem; //220px
        height: auto;
      }
    }
  }

  .navbar-collapse {
    width: 100%;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 5rem; //80px;
      left: 0;
      background-color: $white;
      z-index: 2;
      box-shadow: 0 12px 24px rgba(17,16,62,.12);

      ul {

        @include media-breakpoint-down(sm) {
          margin-top: 3.125rem; // 50px;
          margin-bottom: 3.125rem; // 50px;
        }

        .menu-item {
          padding: 0;

          .active {
            background: transparent
          }
        }
      }
    }

    .navbar-nav {                       // ul

      .menu-item {
        padding: 0;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }

        .nav-link {
          color: $brand-primary;
          font-size: $font-size-base;
          font-weight: $font-weight-bold;
          text-transform: uppercase;
          border-bottom: 2px solid transparent;
          background: $white;
          box-shadow: 0 12px 24px rgba(17,16,62,.12);
          transition: all .3s ease-in-out;

          &:hover {
            color: rgba($brand-primary, .8);
            background: $brand-fourth;
            transition: all .15s ease-in-out;
          }

          @include media-breakpoint-down(md) {
            padding: .5rem .8rem;
            letter-spacing: 1px;
            box-shadow: 0 0 0 rgba(0,0,0,0);
          }

          @include media-breakpoint-up(md) {
            padding: .5rem .5rem;
            font-size: $font-size-small;
            letter-spacing: 1px;
          }

          @include media-breakpoint-up(lg) {
            padding: .5rem .8rem;
            font-size: $font-size-small;
            letter-spacing: 1px;
          }
        }

        .active {
          color: $brand-secondary !important;
          border-bottom: 0;
          transition: all .3s ease-in-out;
        }
      }
    }
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0 12px 24px rgba(17,16,62,.12);
    transition: all .3s ease-in-out;

    @include media-breakpoint-down(sm) {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      width: 100vw;
      border: 0;
      box-shadow: 0 0 0 rgba(0,0,0,0);
      transition: all .3s ease-in-out;

      .dropdown-item {
        color: $brand-primary;
        font-size: $font-size-base;
        font-weight: $font-weight-regular;
        text-align: center;
        border-bottom: 2px solid transparent;
        background: $white;
        transition: all .15s ease-in-out;

        &:hover {
          color: $white;
          background: $brand-primary;
          transition: all .3s ease-in-out;
        }
      }
    }

    .menu-item {
      margin-right: 0 !important;
    }

    .dropdown-item {
      color: $brand-primary;
      font-size: $font-size-base;
      font-weight: $font-weight-regular;
      border-bottom: 2px solid transparent;
      background: $white;
      transition: all .15s ease-in-out;

      &:hover {
        color: $white;
        background: $brand-primary;
        transition: all .3s ease-in-out;
      }
    }
  }
}

.navbar-toggler {
  border: none;
  margin-right: 1rem;

  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }
}

// .collapsed {
//
//   .top-bar {
//     transform: rotate(45deg);
//     transform-origin: 10% 10%;
//   }
//
//   .middle-bar {
//     opacity: 0;
//   }
//
//   .bottom-bar {
//     transform: rotate(-45deg);
//     transform-origin: 10% 90%;
//   }
// }

.icon-bar {
	width: 22px;
	height: 2px;
	background-color: $white;
	display: block;
	transition: all 0.2s;
	margin-top: 4px
}

.fixed-top {
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 1rem 0 !important;
  background: $brand-primary !important;
}
