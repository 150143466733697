// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$iconic-font-path: "../fonts/";

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
);

// Colors
$brand-primary:   rgba(23, 113, 165, 1);
$brand-secondary: rgba(102, 0, 0, 1);
$brand-third:     rgba(51, 51, 51, 1);
$brand-fourth:    rgba(245, 245, 245, 1);
$white:           rgba(255, 255, 255, 1);
$black:           rgba(0, 0, 0, 1);
$brand-fourth-gradient: linear-gradient(0deg, rgba(245,245,245,1) 0%, rgba(255,255,255,1) 50%);

$brand-third: #104360;

// Font-Sizes
$font-size-huge:   2.5rem; //40px
$font-size-big:    1.875rem; //30px
$font-size-normal: 1.25rem; //20px
$font-size-base:   1rem; //16px
$font-size-small:  .875rem; //14px

// Font-Weight
$font-weight-black:  900;
$font-weight-bold:  700;
$font-weight-semibold:  600;
$font-weight-regular:  400;
$font-weight-light:  300;
$font-weight-thin:  200;

$font-family-light: "swis_light";
$font-family-normal: "swis_roman";
$font-family-italic: "swis_italic";
$font-family-bold: "swis_bold";
