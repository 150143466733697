footer {
  position: relative;
  width: 100%;
  height: 6.25rem; //100px
  text-align: center;
}

.navbar-footer {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 1rem; //16px
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    list-style-type: none;

    a {
      color: $brand-primary;
      font-size: $font-size-small;
      font-weight: $font-weight-bold;
      transition: all .15s ease-in;

      &:hover {
        text-decoration: underline;
        transition: all .3s ease-in;
      }
    }
  }
}
