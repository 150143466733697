
/* ==========================================================================
   General
========================================================================== */

@import "../../fonts/swis/stylesheet";

* {
  outline: 0 !important;
}

html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
}

body {
  width: 100%;
  height: fit-content;
  min-height: 100%;
  margin: 0;
  padding: 0;
  color: $brand-primary;
  font-size: 100%;
  font-family: $font-family-normal, sans-serif;
  background: $brand-fourth;
  overflow-x: hidden !important;
}

.main {
  margin-top: 1.25rem; //2rem;

  @include media-breakpoint-up(md) {
    margin-top: 2.5rem; //40px;
  }
}

.wrap {
  position: relative;
  min-height: 100%;
  height: auto;
}

.stage {
  position: relative;
  min-height: 100%;
  height: auto;
}

.content {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  min-height: 100%;
  height: auto;
  width: 100%;
  margin: 0;
  z-index: 1;
}

h1 {
  color: $brand-primary;
  font-size: $font-size-big;
  font-weight: $font-weight-regular;
  line-height: 1.2;

  @include media-breakpoint-down(xs) {
    font-size: $font-size-normal;
  }
  @include media-breakpoint-up(xs) {
    font-size: $font-size-normal;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-big;
  }
}

h2 {
  color: $brand-primary;
  font-size: $font-size-big;
  font-weight: $font-weight-regular;
  line-height: 1.2;

  @include media-breakpoint-down(xs) {
    font-size: $font-size-normal;
  }
  @include media-breakpoint-up(xs) {
    font-size: $font-size-normal;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-big;
  }
}

h1 {
  margin-bottom: 1rem;
  letter-spacing: -.2px;
  mix-blend-mode: multiply;
}
h2 {
  margin-bottom: .65rem;
  letter-spacing: -1px;
}

h3 {
  margin-bottom: .65rem;
  font-size: $font-size-normal;
  font-weight: $font-weight-semibold;
}

h4 {
  margin-bottom: .65rem;
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
}

p {
  color: $brand-primary;
  font-size: $font-size-base;
  line-height: 1.6;

  @include media-breakpoint-up(xs) {
    font-size: $font-size-base;
  }

  a {
    color: $brand-primary;
    font-family: $font-family-normal;
    transition: all .15s ease-out;

    &:hover {
      color: $brand-primary;
      text-decoration: underline;
      transition: all .3s ease-out;
    }
  }
}

.small-link {
  margin-bottom: .5rem;
  font-size: $font-size-small;
  color: $brand-primary;
  transition: all .15s ease-out;

  &:hover {
    color: $brand-primary;
    text-decoration: underline;
    transition: all .3s ease-out;
  }
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.dropcap {
  font-size: $font-size-base * 3;
  line-height: 1.6;

  @include media-breakpoint-down(xs) {
    font-size: $font-size-base * 2.5;
  }
  @include media-breakpoint-up(xs) {
    font-size: $font-size-base * 2.5;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 3;
  }
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, .1);

  @include media-breakpoint-down(lg) {
    border-right: 0 none !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  display: inline-block;
  width: 100%;
  color: $brand-primary;
  font-family: $font-family-bold, sans-serif;
  font-weight: $font-weight-bold;
}

.checkmark {
  position: relative;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
  background-image: url("../images/ico-checkmark.svg");
  background-repeat: no-repeat;
}

/* ==========================================================================
    Preloader
========================================================================== */

.init {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: $brand-fourth;

  .status {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 1;

    img {
      display: inline-block;
      flex: 1;
      width: 20vw;
      margin-top: -10rem; //160px
      text-align: center;

      @media screen and (min-width : 1px) and (max-width: 480px) {
        width: 60vw;
        margin-top: -6.25; //100px
      }
    }
  }
}

.animsition {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* ==========================================================================
    Carousel
========================================================================== */
.carousel {
  width: 100%;
  max-height: 600px;

  .carousel-item {

    img {
      object-fit: cover;
    }
  }
}


/* ==========================================================================
    BG Grid Lines
========================================================================== */

.grid {
  position: fixed;
  width: 100%;
  background: $brand-fourth-gradient;
  background-repeat: no-repeat;
}

.grid-line {
  height: 100vh;
  margin-left: -15px;
  border-left: 1px dashed rgba($brand-third,.25);
}

.grid-line--left {
  margin-right: -15px;
  border-right: 1px dashed rgba($brand-third,.25);
}


/* ==========================================================================
    twelve-point
========================================================================== */

#twelve-point-star {
   height: 100px;
   width: 100px;
   background: blue;
   position: absolute;
}
#twelve-point-star:before {
   height: 100px;
   width: 100px;
   background: blue;
   content:"";
   position: absolute;
   transform: rotate(30deg);
}
#twelve-point-star:after {
   height: 100px;
   width: 100px;
   background: blue;
   content:"";
   position: absolute;
   transform: rotate(-30deg);
}
