/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 25, 2020 */



@font-face {
    font-family: 'swis721_cn_btroman';
    src: url('swis721-cn-bt-roman-webfont.woff2') format('woff2'),
         url('swis721-cn-bt-roman-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'swis721_cn_btbold';
    src: url('swis721-cn-bt-bold-webfont.woff2') format('woff2'),
         url('swis721-cn-bt-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'swis721_cn_btitalic';
    src: url('swis721-cn-bt-italic-webfont.woff2') format('woff2'),
         url('swis721-cn-bt-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'swis721_ltcn_btlight';
    src: url('swis721-cn-bt-light-webfont.woff2') format('woff2'),
         url('swis721-cn-bt-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}