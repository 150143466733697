.carousel-wrapper {
  margin-bottom: 2.5rem; //40px
  padding: 0 !important;
  @include make-col-ready();

  @include media-breakpoint-up(xs) {
    @include make-col(12);
  }

  .carousel-inner {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 24px 48px rgba(17,16,62,0.12);
    mix-blend-mode: multiply;
  }

  img {
    width: 100%;
    max-height: 550px;
    object-fit: cover;
    object-position: center center;
    overflow: hidden;
  }
}

.headline {
  @include make-col-ready();
  margin: .625rem 0; //10px
  padding: 0;

  @include media-breakpoint-up(xs) {
    @include make-col(12);
  }

  h1 {
    margin: 0;
    padding: 0;
  }
}

.text {
  @include make-col-ready();
  @include make-row();
  margin: 0;
  word-break: break-word;


  @include media-breakpoint-up(xs) {
    @include make-col(12);
    flex-direction: row;
    padding: 0;
  }
   @include media-breakpoint-down(md) {
     padding: 0;
   }
  @include media-breakpoint-up(md) {
    @include make-col(6);
    flex-direction: column;

    &:first-child {
      padding-left: 0;
      padding-right: 15px;
    }

    &:last-child {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}

.text-full {
  @include make-col-ready();
  @include make-row();

  @include media-breakpoint-down(xs) {
    @include make-col(12);
    margin: 2rem 0;
    padding-left: 0;
    padding-right: 0;
  }

  @include media-breakpoint-up(xs) {
    @include make-col(12);
    padding-left: 0;
    padding-right: 0;
  }
  @include media-breakpoint-up(md) {
    @include make-col(12);
    margin: 0;
    padding: 0;
  }
}

.checklist {
  padding: 0 1rem;

  li {
    list-style-type: none;
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: $font-size-base;
    background: transparent url(../images/checkmark.svg) no-repeat 0 6px;
    background-size: 6%;

    @include media-breakpoint-down(xs) {
      font-size: $font-size-base * .875;
      background-size: 4%;
    }
    @include media-breakpoint-up(xs) {
      font-size: $font-size-base * .875;
    }
    @include media-breakpoint-up(md) {
      font-size: $font-size-base;
    }
  }
}

.cta {
  height: auto;
  margin: 2rem 0;
  color: $white;
  background: $brand-primary;
  box-shadow: 0 12px 24px rgba(17,16,62,0.12);
  mix-blend-mode: multiply;
  @include make-col-ready();
  @include make-col(12);
  padding: 2rem !important;

  h3 {
    width: 100%;
    color: $white;
    text-align: center;
  }

  p {
    margin-bottom: 2rem;
    color: $white;
  }

  a, a:hover {
    color: $brand-third;
  }

}

.ref-list {
  padding-left: 0;
  list-style-position: outside;

  li {
    margin-bottom: .5rem;
    list-style-type: none;
    list-style-position: inside;
    text-indent: -1.5rem;
    padding-left: 1.5rem;
  }
}

.impressum-kontakt,
.datenschutzerklaerung {
  .contact {
    display: none;
  }
}

.contact {
  position: absolute;
  top: 0vh;
  left: 0vw;
  width: 100px;
  height: 100px;
  z-index: 100;
  transform: rotate(45deg);
  background-color: $brand-primary;

  .contact-number {
    color: $white;
    margin-left: -10px;
    margin-top: 20px;
    text-align: center;
    transform: rotate(-45deg);
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}
